import ScrollTrigger from '@terwanerik/scrolltrigger'
// Create a new ScrollTrigger instance with default options
const navBgTrigger = new ScrollTrigger({
    trigger: {
      once: false
    }
  })

  const entryTrigger = new ScrollTrigger({
    trigger: {
      once: true
    }
  })

navBgTrigger.add('[data-scrolltrigger]',{
  toggle: {
    callback: {
      in: () => {
        document.querySelector(".navbar-large").classList.remove("navbar-large__weiss");
      },
      out: () => {
        document.querySelector(".navbar-large").classList.add("navbar-large__weiss");
      }
  }
}});

navBgTrigger.add('[data-navtrigger-mobile]',{
  toggle: {
    callback: {
      in: () => {
        document.querySelector(".navbar-mobile").classList.remove("navbar-mobile__weiss");
      },
      out: () => {
        document.querySelector(".navbar-mobile").classList.add("navbar-mobile__weiss");
      }
  }
}});


document.addEventListener("DOMContentLoaded", function () {
  const button = document.querySelector(".navbar-mobile__button-round");
  const dropdown = document.querySelector(".navbar-mobile-dropdown");

  // Funktion zum Öffnen des Dropdown-Menüs
  function openDropdown() {
    dropdown.classList.add("navbar-mobile-dropdown__visible");
    // Hinzufügen eines Event Listeners zum Schließen des Menüs bei Klick außerhalb
    document.addEventListener("click", closeDropdownOutside);
  }

  // Funktion zum Schließen des Dropdown-Menüs
  function closeDropdown() {
    dropdown.classList.remove("navbar-mobile-dropdown__visible");
    // Entfernen des Event Listeners zum Schließen des Menüs bei Klick außerhalb
    document.removeEventListener("click", closeDropdownOutside);
  }

  // Funktion zum Schließen des Dropdown-Menüs bei Klick außerhalb
  function closeDropdownOutside(event) {
    if (!dropdown.contains(event.target) && event.target !== button) {
      closeDropdown();
    }
  }

  // Event Listener zum Öffnen des Dropdown-Menüs bei Klick auf den Button
  button.addEventListener("click", function (event) {
    event.stopPropagation(); // Verhindert das Schließen bei Klick auf den Button selbst
    openDropdown();
  });

  // Initialisieren: Dropdown-Menü schließen
  closeDropdown();
});

window.addEventListener('ccm19WidgetClosed', (e) => window.location.reload());

  // Add all html elements with attribute data-trigger
  //trigger.add('[data-trigger]');